import {
  MONEYCORP_CONNECTED,
  MONEYCORP_DISCONNECTED,
  CLEAR_MONEYCORP,
  SET_CURRENCY_PROVIDER,
  SET_PINGPONG_LOADING,
  CONNECT_PINGPONG,
  DISCONNECT_PINGPONG,
  CLEAR_PINGPONG,
  CAMBRIDGE_CONNECTION_STATUS,
  CONNECT_OFX,
  DISCONNECT_OFX,
  DISCONNECT_CAMBRIDGE,
  CLEAR_CAMBRIDGE,
  CLEAR_OFX,
  SET_USERS_DATA,
  CONNECT_CAMBRIDGE_REQUEST,
  CONNECT_CAMBRIDGE_SUCCESS,
  CONNECT_CAMBRIDGE_FAILURE,
} from '../actions/types'

const initialState = {
  isMoneyCorpConnected: false,
  isPingPongConnected: false,
  isOfxConnected: false,
  camBridgeStatus: 'disconnected',
  providerSelected: '',
  currentProviderSelected: '',
  loading: true,
  pingPongLoading: true,
  cambridgeConnectState: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
  },
}

const currencyProvider = (state = initialState, action: any) => {
  const { type, payload } = action

  const getProvider = () => {
    const currentUser = payload.data.find(
      (item: any) => item.user === payload.id
    )
    if (payload.data.length && currentUser) {
      if (currentUser.companies.length) {
        const preSelectedCompany = currentUser.companies.find(
          (item: any) => item.selected
        )
        if (preSelectedCompany) {
          return preSelectedCompany.providerSelected
        }
      } else {
        return state.providerSelected
      }
    } else {
      return state.providerSelected
    }
  }

  switch (type) {
    case SET_USERS_DATA:
      return {
        ...state,

        currentProviderSelected: getProvider(),
      }
    case MONEYCORP_CONNECTED:
      return {
        ...state,
        isMoneyCorpConnected: true,
        loading: false,
      }
    case MONEYCORP_DISCONNECTED:
      return {
        ...state,
        isMoneyCorpConnected: false,
      }
    case CLEAR_MONEYCORP:
      return {
        ...state,
        isMoneyCorpConnected: false,
        loading: true,
      }
    case CONNECT_PINGPONG:
      return {
        ...state,
        isPingPongConnected: true,
        loading: false,
      }
    case DISCONNECT_PINGPONG:
      return {
        ...state,
        isPingPongConnected: false,
      }
    case DISCONNECT_CAMBRIDGE:
      return {
        ...state,
        camBridgeStatus: 'disconnected',
      }
    case CLEAR_PINGPONG:
      return {
        ...state,
        isPingPongConnected: false,
        providerSelected: '',
        loading: true,
      }
    case SET_CURRENCY_PROVIDER:
      localStorage.setItem('providerSelected', payload)
      return {
        ...state,
        providerSelected: payload,
      }
    case SET_PINGPONG_LOADING:
      return {
        ...state,
        pingPongLoading: payload,
      }
    case CAMBRIDGE_CONNECTION_STATUS:
      return {
        ...state,
        camBridgeStatus: payload,
      }
    case CONNECT_OFX:
      return {
        ...state,
        isOfxConnected: true,
      }
    case DISCONNECT_OFX:
      return {
        ...state,
        isOfxConnected: false,
      }
    case CONNECT_CAMBRIDGE_REQUEST: {
      return {
        ...state,
        cambridgeConnectState: {
          ...state.cambridgeConnectState,
          isFetching: true,
          isFetched: false,
          isFailed: false,
        },
      }
    }

    case CONNECT_CAMBRIDGE_SUCCESS: {
      return {
        ...state,
        cambridgeConnectState: {
          ...state.cambridgeConnectState,
          isFetching: false,
          isFetched: true,
          isFailed: false,
        },
      }
    }
    case CONNECT_CAMBRIDGE_FAILURE: {
      return {
        ...state,
        cambridgeConnectState: {
          ...state.cambridgeConnectState,
          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    }
    case CLEAR_OFX:
      return {
        ...state,
        isOfxConnected: false,
      }
    case CLEAR_CAMBRIDGE:
      return {
        ...state,
        camBridgeStatus: 'disconnected',
      }

    default:
      return state
  }
}

export default currencyProvider
