import {
  GET_COREPAY_OPTIONS_REQUST,
  GET_COREPAY_OPTIONS_SUCCESS,
  GET_COREPAY_OPTIONS_FAILURE,
  CREATE_CORPAY_ONBOARDING_APP_REQUEST,
  CREATE_CORPAY_ONBOARDING_APP_SUCCESS,
  CREATE_CORPAY_ONBOARDING_APP_FAILURE,
  GET_COREPAY_REQUIRED_FILES_REQUST,
  GET_COREPAY_REQUIRED_FILES_SUCCESS,
  GET_COREPAY_REQUIRED_FILES_FAILURE,
  EDIT_UPLOADED_DOCUMENTS,
  LOGOUT,
} from '../actions/types'

import { ClientOnboardingStateI } from 'types/clientOnboarding'

const initialState: ClientOnboardingStateI = {
  applicationSubmit: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    data: [],
  },
  requiredFiles: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    data: null,
  },
  options: {
    regions: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
    nature_of_businesses: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
    business_types: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
    trade_volumes: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
    annual_volumes: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
    purpose_of_transaction: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
    application_types: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
    states: {
      isFetching: false,
      isFetched: false,
      isFailed: false,
      items: [],
    },
  },
}

const clientOnboarding = (state = initialState, action: any) => {
  const { type, payload } = action
  switch (type) {
    case GET_COREPAY_OPTIONS_REQUST:
      return {
        ...state,

        options: {
          ...state.options,

          [payload.type]: {
            ...state.options[payload.type],

            isFetching: true,
            isFetched: false,
            isFailed: false,
          },
        },
      }
    case GET_COREPAY_OPTIONS_SUCCESS:
      return {
        ...state,

        options: {
          ...state.options,

          [payload.type]: {
            ...state.options[payload.type],

            isFetching: false,
            isFetched: true,
            isFailed: false,
            items: payload.content[payload.key].filter(
              (item: any) => item.value !== 'Undefined'
            ),
          },
        },
      }
    case GET_COREPAY_OPTIONS_FAILURE:
      return state
    case CREATE_CORPAY_ONBOARDING_APP_REQUEST:
      return {
        ...state,

        applicationSubmit: {
          ...state.applicationSubmit,

          isFetching: true,
          isFailed: false,
          isFetched: false,
          data: [],
        },
      }
    case CREATE_CORPAY_ONBOARDING_APP_SUCCESS: {
      return {
        ...state,

        applicationSubmit: {
          ...state.applicationSubmit,

          isFetching: false,
          isFailed: false,
          isFetched: true,
          data: payload,
        },
      }
    }
    case CREATE_CORPAY_ONBOARDING_APP_FAILURE: {
      const getData = () => {
        if (payload.errorsData.length) {
          return payload.errorsData
        } else if (payload.corpayErrorsData) {
          if (payload.corpayErrorsData.errors.length) {
            return payload.corpayErrorsData.errors
              .map((item: any) => {
                return {
                  ...item,

                  corpayError: true,
                }
              })
              .filter((item: any) => item.key !== 'ErrorID')
          }
        } else {
          return []
        }
      }
      return {
        ...state,

        applicationSubmit: {
          ...state.applicationSubmit,

          isFetching: false,
          isFailed: true,
          isFetched: true,
          data: getData(),
        },
      }
    }
    case GET_COREPAY_REQUIRED_FILES_REQUST: {
      return {
        ...state,

        requiredFiles: {
          ...state.requiredFiles,

          isFetching: true,
          isFetched: false,
          isFailed: false,
        },
      }
    }
    case GET_COREPAY_REQUIRED_FILES_SUCCESS: {
      return {
        ...state,

        requiredFiles: {
          ...state.requiredFiles,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          data: payload,
        },
      }
    }
    case GET_COREPAY_REQUIRED_FILES_FAILURE: {
      return {
        ...state,

        requiredFiles: {
          ...state.requiredFiles,

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    }
    case EDIT_UPLOADED_DOCUMENTS: {
      return {
        ...state,

        requiredFiles: {
          ...state.requiredFiles,

          data: {
            ...state.requiredFiles.data,

            files: !state.requiredFiles.data.files.find(
              (item: any) => item.document_id === payload.id
            )
              ? [
                  ...state.requiredFiles.data.files,
                  {
                    name: payload.name,
                    document_id: payload.id,
                    uploaded_at: '',
                    submitted_at: '',
                    retries: 0,
                  },
                ]
              : state.requiredFiles.data.files.map((item: any) => {
                  if (item.document_id === payload.id) {
                    return {
                      ...item,

                      name: payload.name,
                    }
                  } else {
                    return item
                  }
                }),
          },
        },
      }
    }
    case LOGOUT: {
      return initialState
    }
    default:
      return state
  }
}

export default clientOnboarding
