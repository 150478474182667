import {
  SET_USERS_DATA,
  LOGIN_SUCCESS,
  REFRESH_TOKEN,
  RECONNECT_ON_REFRESH,
  TOGGLE_LOADING,
  EXPIRED_PASSWORD,
  REQUEST_PASSWORD_CHANGE_SUCCESS,
  RECAPTCHA_FAIL,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  LOGIN_FAIL,
  SKIP_OFX_REAUTHENTICATE,
  PASSWORD_CHANGE_FAIL,
  REQUEST_PASSWORD_CHANGE_FAIL,
  LOGOUT,
} from '../actions/types'
import setAuthToken from '../utils/setAuthToken'
import getExpirationTime from '../utils/getExpirationTime'
import { IdentityState, LoginData } from 'types/identity'

const initialState: IdentityState = {
  skipOfxReauthenticate: false,
  access_token: '',
  refresh_token: '',
  isAuthenticated: false,
  loading: false,
  first_name: '',
  last_name: '',
  email_confirmed: false,
  phone_confirmed: false,
  isPasswordChangeRequest: false,
  email: '',
  recaptchaFail: '',
  usersDataState: [],
  error: {
    message: '',
  },
  profile: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    data: {
      email: '',
      phone_number: '',
      sub: '',
    },
    isUpdating: false,
    isUpdated: false,
    isUpdateFailed: false,
    updating: '',
  },
  changePasswordState: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    errorMessage: '',
  },
}

const clearItems = () => {
  localStorage.removeItem('token')
  localStorage.removeItem('refresh_token')
  localStorage.removeItem('first_name')
  localStorage.removeItem('last_name')
  localStorage.removeItem('expiration_time')
  localStorage.removeItem('email')
  localStorage.removeItem('reasons_last_update')
  localStorage.removeItem('reasons')
  localStorage.removeItem('instruments_last_update')
  localStorage.removeItem('instruments')
  localStorage.removeItem('last_path')
  localStorage.removeItem('last_search')
  localStorage.removeItem('previous_path')
  localStorage.removeItem('bills_upcoming_path')
  localStorage.removeItem('bills_upcoming_search')
  setAuthToken(null)
}

const setItems = (payload: LoginData) => {
  localStorage.setItem('token', payload.access_token)
  localStorage.setItem('refresh_token', payload.refresh_token)
  localStorage.setItem('first_name', payload.first_name)
  localStorage.setItem('last_name', payload.last_name)
  localStorage.setItem(
    'expiration_time',
    getExpirationTime(payload.access_token)
  )
  setAuthToken(payload.access_token)
}

const identity = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_USERS_DATA:
      return {
        ...state,

        usersDataState: payload.data,
      }
    case LOGIN_SUCCESS:
      setItems(payload)
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
        error: {
          ...state.error,

          message: '',
        },
      }
    case REFRESH_TOKEN:
      setItems(payload)
      return {
        ...state,
        ...payload,
        isAuthenticated: true,
        loading: false,
      }
    case RECONNECT_ON_REFRESH:
      return {
        ...state,
        isAuthenticated: true,
        first_name: payload.first_name,
        last_name: payload.last_name,
        loading: false,
      }
    case TOGGLE_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case EXPIRED_PASSWORD:
      setAuthToken(payload.access_token)
      return {
        ...state,
        isAuthenticated: false,
        email: payload.email,
        access_token: payload.access_token,
        loading: false,
      }
    case REQUEST_PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
      }
    case RECAPTCHA_FAIL:
      return {
        ...state,
        recaptchaFail: payload,
      }
    case FETCH_PROFILE_REQUEST:
      return {
        ...state,

        profile: {
          ...state.profile,

          isFetching: true,
          isFetched: false,
        },
      }
    case FETCH_PROFILE_SUCCESS:
      return {
        ...state,

        profile: {
          ...state.profile,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          data: payload,
        },
      }
    case FETCH_PROFILE_FAILURE:
      return {
        ...state,

        profile: {
          ...state.profile,

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,

        profile: {
          ...state.profile,

          isUpdating: true,
          isUpdated: false,
          isUpdateFailed: false,
          updating: payload,
        },
      }
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,

        profile: {
          ...state.profile,

          isUpdating: false,
          isUpdated: true,
          isUpdateFailed: false,
          updating: '',
          data: {
            ...state.profile.data,

            ...payload,
          },
        },
      }
    case UPDATE_PROFILE_FAILURE:
      return {
        ...state,

        profile: {
          ...state.profile,

          isUpdating: false,
          isUpdated: true,
          isUpdateFailed: true,
          updating: '',
        },
      }
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,

        changePasswordState: {
          ...state.changePasswordState,

          isFetching: true,
          isFetched: false,
          isFailed: false,
        },
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,

        changePasswordState: {
          ...state.changePasswordState,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          errorMessage: '',
        },
      }
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,

        changePasswordState: {
          ...state.changePasswordState,

          isFetching: false,
          isFetched: true,
          isFailed: true,
          errorMessage: payload,
        },
      }
    case LOGIN_FAIL:
      clearItems()
      return {
        ...state,

        error: {
          ...state.error,

          message: payload,
        },
        access_token: null,
        loading: false,
        isAuthenticated: false,
        email_confirmed: false,
        phone_confirmed: false,
        isPasswordChangeRequest: false,
      }
    case SKIP_OFX_REAUTHENTICATE: {
      return {
        ...state,
        skipOfxReauthenticate: true,
      }
    }
    case PASSWORD_CHANGE_FAIL:
    case REQUEST_PASSWORD_CHANGE_FAIL:
    case LOGOUT:
      clearItems()
      return {
        ...state,
        skipOfxReauthenticate: false,
        access_token: null,
        loading: false,
        isAuthenticated: false,
        email_confirmed: false,
        phone_confirmed: false,
        isPasswordChangeRequest: false,
        profile: {
          isFetching: false,
          isFetched: false,
          isFailed: false,
          data: null,
        },
        changePasswordState: {
          isFetching: false,
          isFetched: false,
          isFailed: false,
          errorMessage: '',
        },
      }
    default:
      return state
  }
}

export default identity
