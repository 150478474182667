const darkStyles = {
  colors: {
    gray: {
      500: '#CCCDD1',
      700: '#33383E',
      1000: '#6A6969',
    },
    brand: {
      50: '#cbbff8',
      100: '#876cea',
      200: '#008CFF',
      300: '#0072d0',
      400: '#0072d0',
      500: '#3915bc',
      600: '#300eaa',
      700: '#1c0377',
      800: '#130156',
      900: '#0e0042',
    },
    WDGreen: '#4B980F',
    grayColor: '#2B2E33',
    lightGrayColor: '#828282',
    detailsBackground: '#2D3037',
    connectButtonBg: '#1e900e',
    cardDetailsGradentBackground:
      'linear-gradient(127.09deg, rgba(24, 29, 60, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)',
    tableHeadBg: '#393D43',
    tableRowBgEven: '#2b2e33',
    tableRowBgOdd: '#33383E',
  },

  styles: {
    global: () => ({
      body: {
        fontFamily: 'Nunito Sans',
      },
      '*::placeholder': {
        color: 'gray.400',
      },
      html: {
        fontFamily: 'Nunito Sans',
      },
    }),
  },
}

const lightStyles = {
  colors: {
    gray: {
      500: '#CCCDD1',
      700: '#dddddd',
      1000: '#6A6969',
    },
    brand: {
      50: '#cbbff8',
      100: '#876cea',
      200: '#008CFF',
      300: '#0072d0',
      400: '#0072d0',
      500: '#3915bc',
      600: '#300eaa',
      700: '#1c0377',
      800: '#130156',
      900: '#0e0042',
    },
    WDGreen: '#4B980F',
    grayColor: '#eaeaea',
    grayTextColor: '#494949',
    lightGrayColor: '#828282',
    lightBlueColor: '#DCEFFE',
    detailsBackground: 'white',
    connectButtonBg: '#1e900e',
    cardDetailsGradentBackground:
      'linear-gradient(127.09deg, rgba(24, 29, 60, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)',
    tableHeadBg: 'white',
    tableRowBgEven: '#EAEAEA',
    tableRowBgOdd: 'white',
  },

  styles: {
    global: () => ({
      body: {
        fontFamily: 'Nunito Sans',
      },
      '*::placeholder': {
        color: 'gray.400',
      },
      html: {
        fontFamily: 'Nunito Sans',
      },
    }),
  },
}

const commonStyles = {
  config: {
    initialColorMode: localStorage.getItem('chakra-ui-color-mode') || 'dark',
  },
  sizes: {
    inputHeight: '32px',
    buttonHeight: '35px',
  },
  fonts: {
    heading: 'Nunito Sans',
    body: 'Nunito Sans',
  },
}

export const globalStylesDark = {
  ...commonStyles,

  ...darkStyles,
}

export const globalStylesLight = {
  ...commonStyles,

  ...lightStyles,
}
