import { BeneficiaryTemplatesState } from 'types/beneficiaryTemplates'
import {
  GET_BENEFICIARY_TEMPLATES,
  RESET_BENEFICIARY_TEMPLATES,
  SET_PINGPONG_TEMPLATE,
  SET_PINGPONG_TEMPLATE_ERROR,
  FETCH_TEMPLATE_SUCCESS,
  FETCH_TEMPLATE_REQUEST,
  FETCH_TEMPLATE_FAILURE,
  SET_OFX_TEMPLATE,
  GET_CAMBRIDGE_PAYMENT_METHODS,
  RESET_CAMBRIDGE_TEMPLATE,
  RESET_CAMBRIDGE_PAYMENTS,
  RESET_OFX_TEMPLATE,
  CREATE_BENEFICIARY_FAILURE,
  REMOVE_TEMPLATE_ERROR,
} from '../actions/types'

import _ from 'lodash'

const initialState: BeneficiaryTemplatesState = {
  pingpong_template: [],
  pingpong_template_error: false,
  cambridge_template: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    items: [
      {
        name: 'account_holder_name',
        label: 'Name',
        error_message:
          'Name is invalid. Value should be 3 to 100 characters long.',
        mandatory: true,
        reg_ex: '^.{3,100}$',
      },
      {
        name: 'destination_country',
        label: 'Destination Country',
        error_message: '',
        mandatory: true,
        reg_ex: '[\n        \n      ]',
      },
      {
        name: 'bank_currency',
        label: 'Currency',
        error_message: '',
        mandatory: true,
        reg_ex: '[\n        \n      ]',
      },
      {
        name: 'payment_methods',
        label: 'Payment Methods',
        error_message: '',
        mandatory: true,
        reg_ex: '[\n        \n      ]',
      },
      {
        name: 'preferred_method',
        label: 'Preferred Method',
        error_message: '',
        mandatory: true,
        reg_ex: '[\n        \n      ]',
      },
      {
        name: 'account_holder_country',
        label: 'Country',
        error_message: '',
        mandatory: true,
        reg_ex: '^.{2,2}$',
      },
      {
        name: 'account_holder_address1',
        label: 'Address',
        error_message: 'Address Line 1 must be less than 1000 characters',
        mandatory: true,
        reg_ex: '^.{0,1000}$',
      },
      {
        name: 'account_holder_city',
        label: 'City',
        error_message: 'City must be less than 100 characters',
        mandatory: true,
        reg_ex: '^.{0,100}$',
      },
      {
        name: 'bank_name',
        label: 'Name',
        error_message: 'Beneficiary Bank Name must be less than 250 characters',
        mandatory: true,
        reg_ex: '^.{0,250}$',
      },
      {
        name: 'bank_country',
        label: 'Country',
        error_message: '',
        mandatory: true,
        reg_ex: '^.{2,2}$',
      },
      {
        name: 'bank_city',
        label: 'City',
        error_message: 'City must be less than 100 characters',
        mandatory: true,
        reg_ex: '^.{0,100}$',
      },
      {
        name: 'bank_address_line1',
        label: 'Address',
        error_message: 'Bank Address Line 1 must be less than 1000 characters',
        mandatory: true,
        reg_ex: '^.{0,1000}$',
      },
    ],
  },
  cambridge_payment_methods: [],
  ofx_template: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    items: [
      {
        name: 'country_code',
        label: 'Country',
        error_message: '',
        mandatory: true,
        reg_ex: '[\n        \n      ]',
      },
      {
        name: 'name',
        label: 'Name',
        mandatory: true,
      },
      {
        name: 'currency',
        label: 'Currency',
        mandatory: true,
        reg_ex: '/[A-Z]{3}/',
      },
      {
        name: 'email',
        label: 'Email',
        mandatory: false,
      },
      {
        name: 'reference',
        label: 'Reference',
        mandatory: false,
      },
      {
        name: 'classification',
        label: 'Classification',
        mandatory: true,
        reg_ex:
          '/(?<![\\w\\d])Own Account(?![\\w\\d])|(?<![\\w\\d])3rd Party(?![\\w\\d])/',
      },
      {
        name: 'payment_reason',
        label: 'Payment Reason',
        mandatory: true,
      },
      {
        name: 'bank_name',
        label: 'Name',
        mandatory: true,
      },
      // {
      //   name: 'bank_branch_name',
      //   label: 'Branch Name',
      //   mandatory: true,
      // },
      // {
      //   name: 'bank_code',
      //   label: 'Institution Number',
      //   mandatory: true,
      // },
      // {
      //   name: 'branch_code',
      //   label: 'Transit/Branch Number',
      //   mandatory: true,
      // },
      // {
      //   name: 'account',
      //   label: 'Account Number',
      //   mandatory: true,
      // },
      {
        name: 'bank_address.address_line',
        label: 'Address',
        mandatory: true,
      },
      {
        name: 'bank_address.post_code',
        label: 'PostCode',
        mandatory: true,
      },
      {
        name: 'bank_address.city_suburb',
        label: 'City/Suburb',
        mandatory: true,
      },
      {
        name: 'bank_address.state_province',
        label: 'State/Province',
        mandatory: true,
      },
      {
        name: 'bank_address.country',
        label: 'Country',
        mandatory: true,
      },
      {
        name: 'beneficiary_address.address_line',
        label: 'Address',
        mandatory: true,
      },
      {
        name: 'beneficiary_address.city_suburb',
        label: 'City/Suburb',
        mandatory: true,
      },
      {
        name: 'beneficiary_address.post_code',
        label: 'PostCode',
        mandatory: true,
      },
      {
        name: 'beneficiary_address.country',
        label: 'Country',
        mandatory: true,
      },
    ],
  },
}

const beneficiaryTemplates = (state = initialState, action: any) => {
  const { type, payload } = action
  let providerTemplate
  let items

  switch (type) {
    case GET_BENEFICIARY_TEMPLATES:
      return {
        ...state,
        ...payload,
      }
    case REMOVE_TEMPLATE_ERROR:
      return {
        ...state,

        ofx_template: {
          ...state.ofx_template,

          items: state.ofx_template.items.map(item => {
            if (item.name === payload) {
              return {
                ...item,

                error_message: '',
              }
            } else {
              return item
            }
          }),
        },
      }
    case CREATE_BENEFICIARY_FAILURE:
      if (payload.templateErrors.length) {
        return {
          ...state,

          ofx_template: {
            ...state.ofx_template,

            items: state.ofx_template.items.map(item => {
              var template = payload.templateErrors.find(
                (err: any) => err.field === item.name
              )
              if (template) {
                return {
                  ...item,

                  error_message: template.message,
                }
              } else {
                return item
              }
            }),
          },
        }
      }
      return state
    case FETCH_TEMPLATE_REQUEST:
      providerTemplate = payload.providerTemplate
      return {
        ...state,

        [providerTemplate]: {
          ...state[providerTemplate],

          isFetching: true,
          isFetched: false,
          isFailed: false,
        },
      }
    case FETCH_TEMPLATE_SUCCESS:
      providerTemplate = payload.providerTemplate
      items = payload.items
      const data = [
        ...initialState[providerTemplate].items,
        ...items.map((item: any) => {
          if (item.name === 'account_holder_region') {
            return {
              ...item,

              label: 'Region',
            }
          } else if (item.name === 'account_holder_postal') {
            return {
              ...item,

              label: 'Postal',
            }
          } else if (item.name === 'bank_region') {
            return {
              ...item,

              label: 'Region',
            }
          } else if (item.name === 'bank_postal') {
            return {
              ...item,

              label: 'Postal',
            }
          } else {
            return item
          }
        }),
      ]
      return {
        ...state,
        [providerTemplate]: {
          ...state[providerTemplate],

          isFetched: true,
          isFailed: false,
          isFetching: false,
          items: _.uniqBy(data, 'name'),
        },
      }
    case FETCH_TEMPLATE_FAILURE:
      providerTemplate = payload.providerTemplate
      return {
        ...state,

        [providerTemplate]: {
          ...state[providerTemplate],

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    case RESET_CAMBRIDGE_TEMPLATE:
      return {
        ...state,
        cambridge_template: initialState.cambridge_template,
      }
    case RESET_CAMBRIDGE_PAYMENTS:
      return {
        ...state,
        cambridge_payment_methods: [],
      }
    case SET_OFX_TEMPLATE:
      return {
        ...state,
        ofx_template: payload,
      }
    case RESET_OFX_TEMPLATE:
      return {
        ...state,
        ofx_template: initialState.ofx_template,
      }
    case GET_CAMBRIDGE_PAYMENT_METHODS:
      return {
        ...state,
        cambridge_payment_methods: payload,
      }
    case SET_PINGPONG_TEMPLATE:
      return {
        ...state,
        pingpong_template: payload,
        pingpong_template_error: false,
      }
    case SET_PINGPONG_TEMPLATE_ERROR:
      return {
        ...state,
        pingpong_template_error: true,
      }
    case RESET_BENEFICIARY_TEMPLATES:
      return {
        ...state,
        pingpong_template: [],
        pingpong_template_error: false,
        cambridge_template: initialState.cambridge_template,
        cambridge_payment_methods: [],
        ofx_template: initialState.ofx_template,
      }
    default:
      return state
  }
}

export default beneficiaryTemplates
