export const bgAuth = {
  styles: {
    global: () => ({
      body: {
        bg: '#1E2024',
        bgSize: 'cover',
        bgPosition: 'center center',
      },
    }),
  },
}
