import { GET_ACCOUNTS } from '../actions/types'

const initialState = {
  accounts: [],
}

const accounts = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case GET_ACCOUNTS:
      return {
        ...state,
        accounts: payload,
      }

    default:
      return state
  }
}

export default accounts
