export const parseSymbols = (instruments: any) => {
  let symbols: any = {}
  if (instruments && instruments.length > 0) {
    for (let i = 0; i < instruments.length; i++) {
      symbols[instruments[i].code] = []
    }
  }

  return symbols
}
