import axios from 'axios'
import { push } from '@lagunovsky/redux-react-router'
import {
  GETCOMPANIES,
  COMPANY_DETAILS,
  UPDATE_COMPANY,
  CREATE_COMPANY,
  ADD_COMPANY,
  TOGGLE_EDIT,
  TOGGLE_COMPANY_LOADING,
  RECONNECT_ON_REFRESH,
  CONNECT_QUICKBOOKS,
  DISCONNECT_QUICKBOOKS,
  SET_QUICKBOOK_STATUS,
  GET_COMPANY_DETAILS_REQUEST,
  GET_COMPANY_DETAILS_SUCCESS,
  GET_COMPANY_DETAILS_FAILURE,
  SET_USERS_DATA,
} from './types'
import { WORLDESK_URL, header_config } from '../api/config'
import { notify } from './notifications'
import isTokenValid from '../utils/isTokenValid'
import { setInit } from './init'
import { logout } from './identity'
import { updateActivityTime } from './session'
import { checkErrorStatus } from './error'
import { getCurrencies } from './references'
import { setCurrencyProvider } from './currecyProviders'
import { loadState, saveState } from 'utils/persist'
import _ from 'lodash'
import { CompaniesResDataI, CompanyDetailsResDataI } from 'types/company'

export const getCompanies =
  (silent?: boolean, withoutLoading?: boolean) =>
  async (dispatch: any, getState: any) => {
    const userId = getState().identity.profile.data.sub
    dispatch(updateActivityTime())
    if (!isTokenValid()) return

    try {
      dispatch({ type: GET_COMPANY_DETAILS_REQUEST, payload: withoutLoading })
      const res: CompaniesResDataI = await axios.get(
        WORLDESK_URL + '/management/api/company/',
        header_config
      )

      dispatch({
        type: GETCOMPANIES,
        payload: res.data,
      })

      dispatch({ type: GET_COMPANY_DETAILS_SUCCESS })

      const company = res.data

      if (company && company.length < 1) {
        dispatch(push('/create-company'))
      } else {
        //if company found, request details immediately
        const usersData = loadState('users-data') || []
        const currentUser = usersData.find((item: any) => item.user === userId)
        if (usersData.length && currentUser) {
          if (currentUser) {
            if (currentUser.companies.length) {
              const preSelectedCompany = currentUser.companies.find(
                (item: any) => item.selected
              )
              if (preSelectedCompany) {
                dispatch(getCompanyDetails(preSelectedCompany.id))
              } else {
                dispatch(getCompanyDetails(company[0].id))
              }
            }
          }
        } else {
          dispatch(getCompanyDetails(company[0].id))
        }
      }
    } catch (err) {
      dispatch({ type: GET_COMPANY_DETAILS_FAILURE })
      if (!silent) {
        dispatch(notify('Failed to get List of companies', 'error'))
        dispatch(checkErrorStatus(err))
      }
    }
  }

export const reconnectOnRefresh = () => async (dispatch: any) => {
  try {
    dispatch({
      type: RECONNECT_ON_REFRESH,
      payload: {
        first_name: localStorage.getItem('first_name'),
        last_name: localStorage.getItem('last_name'),
      },
    })
    dispatch(setInit(false))
  } catch (err) {
    dispatch(logout())
    dispatch(setInit(false))
    dispatch(checkErrorStatus(err))
  }
}

export const setUserDataAction =
  ({
    company_id,
    providerSelected,
  }: {
    company_id: string
    providerSelected?: string
  }) =>
  async (dispatch: any, getState: any) => {
    const usersData = loadState('users-data') || []
    const userId = getState().identity.profile.data.sub
    const { company } = getState().company

    const currentUser = usersData.find((item: any) => item.user === userId)

    let newUsersData = usersData

    const setCompanies = (item: any) => {
      const diff = company.filter(
        ({ id: id1 }: { id: string }) =>
          !currentUser.companies.some(
            ({ id: id2 }: { id: string }) => id2 === id1
          )
      )

      if (diff.length) {
        return _.flatten([...item.companies, diff])
      }
      return item.companies.map((companyItem: any) => {
        if (companyItem.id === company_id) {
          if (providerSelected || providerSelected === '') {
            return {
              ...companyItem,

              selected: true,
              providerSelected,
            }
          } else {
            return {
              ...companyItem,

              selected: true,
            }
          }
        } else {
          return {
            ...companyItem,

            selected: false,
          }
        }
      })
    }

    if (!currentUser) {
      const userData = {
        user: userId,
        companies: company.map((companyItem: any) => {
          if (companyItem.id === company_id) {
            return {
              ...companyItem,

              selected: true,
            }
          } else {
            return {
              ...companyItem,

              selected: false,
            }
          }
        }),
      }
      newUsersData.push(userData)
    } else {
      newUsersData = usersData.map((item: any) => {
        if (item.user === userId) {
          return {
            ...item,

            companies: setCompanies(item),
          }
        } else {
          return item
        }
      })
    }

    localStorage.setItem('companyId', company_id)

    saveState(newUsersData, 'users-data')
    dispatch({
      type: SET_USERS_DATA,
      payload: { data: newUsersData, id: userId },
    })
  }

export const getCompanyDetails =
  (company_id: string) => async (dispatch: any, getState: any) => {
    const currentLocation = getState().router.location.pathname
    const userId = getState().identity.profile.data.sub
    const currentCompany = getState().company.company.find(
      (item: any) => item.id === company_id
    )

    dispatch(setUserDataAction({ company_id }))

    dispatch(updateActivityTime())
    if (!isTokenValid()) return

    try {
      dispatch(getCurrencies())

      const res: CompanyDetailsResDataI = await axios.get(
        WORLDESK_URL +
          `/management/api/company/details?companyid=${encodeURIComponent(
            company_id
          )}`,
        header_config
      )

      dispatch({
        type: COMPANY_DETAILS,
        payload: {
          ...res.data,
          myRole: currentCompany ? currentCompany.role : '',
        },
      })

      if (
        res.data.integrations &&
        res.data.integrations.hasOwnProperty('quick_books')
      ) {
        const { quick_books } = res.data.integrations
        if (quick_books.length > 0) {
          dispatch({
            type: SET_QUICKBOOK_STATUS,
            payload: quick_books[0].status,
          })
          dispatch({ type: CONNECT_QUICKBOOKS })
        } else {
          dispatch({ type: DISCONNECT_QUICKBOOKS })
        }
      } else {
        dispatch({ type: DISCONNECT_QUICKBOOKS })
      }

      if (
        res &&
        res.data &&
        res.data.integrations &&
        res.data.integrations.currency_providers.length
      ) {
        let { currency_providers } = res.data.integrations

        if (currency_providers.length > 0) {
          let previousProvider = ''

          const usersDataState = loadState('users-data')

          const currentUser = usersDataState.find(
            (item: any) => item.user === userId
          )
          if (currentUser) {
            if (currentUser.companies && currentUser.companies.length) {
              const preSelectedCompany = currentUser.companies.find(
                (item: any) => item.id === company_id
              )
              if (preSelectedCompany) {
                previousProvider = preSelectedCompany.providerSelected
              }
            }
          }

          if (
            previousProvider &&
            currency_providers.find((c: any) => c.type === previousProvider)
          ) {
            dispatch(setCurrencyProvider(previousProvider))
          } else {
            dispatch(setCurrencyProvider(currency_providers[0].type))
          }
        }
      } else if (
        (res && res.data && !('integrations' in res.data)) ||
        (res &&
          res.data &&
          res.data.integrations &&
          res.data.integrations.currency_providers.length === 0)
      ) {
        if (currentLocation !== '/eula') {
          dispatch(push('/settings/integrations'))
          dispatch(setCurrencyProvider(''))
        }
      }
    } catch (err: any) {
      if (err.response && err.response.status === 403) {
        dispatch(
          notify('You are not allowed to access company resources', 'error')
        )
      } else {
        dispatch(notify('Failed to get Company Details', 'error'))
      }
      dispatch(checkErrorStatus(err))
    }
  }

export const getIntegrations =
  (company_id: string) => async (dispatch: any) => {
    dispatch(updateActivityTime())
    if (!isTokenValid()) return

    try {
      const res = await axios.get(
        WORLDESK_URL +
          `/management/api/company/details?companyid=${encodeURIComponent(
            company_id
          )}`,
        header_config
      )
      dispatch({ type: COMPANY_DETAILS, payload: res.data })
    } catch (err) {
      dispatch(notify('Failed to get Company Details', 'error'))
      dispatch(checkErrorStatus(err))
    }
  }

export const updateCompany = (formData: any) => async (dispatch: any) => {
  dispatch(updateActivityTime())
  if (!isTokenValid()) return

  const {
    id,
    name,
    currency,
    description,
    email,
    phone,
    street,
    city,
    state,
    country,
    post_code,
    business_number,
  } = formData

  const body = JSON.stringify({
    id,
    name,
    currency,
    description,
    email,
    phone,
    address: {
      street,
      city,
      state,
      country,
      post_code,
    },
    business_number,
  })

  try {
    const res = await axios.put(
      WORLDESK_URL +
        `/management/api/company?companyid=${encodeURIComponent(id)}`,
      body,
      header_config
    )
    dispatch({
      type: UPDATE_COMPANY,
      payload: res.data,
    })

    dispatch(notify('Company Details successfully updated.', 'success'))
    dispatch(setEdit(false))
    dispatch(push('/settings'))
  } catch (err: any) {
    console.log('error', err)
    console.log('Error response:', err.response)

    if (err && err.response && err.response.data && err.response.data.errors) {
      const { errors } = err.response.data
      if (errors && errors.Currency) {
        errors.Currency.forEach((err: any) => dispatch(notify(err, 'error')))
      } else if (errors && errors['Address.Country']) {
        errors['Address.Country'].forEach((err: any) =>
          dispatch(notify(err, 'error'))
        )
      } else if (errors && errors.Email) {
        errors.Email.forEach((err: any) => dispatch(notify(err, 'error')))
      } else if (errors && errors.Phone) {
        errors.Phone.forEach((err: any) => dispatch(notify(err, 'error')))
      }
    }
    dispatch(notify('Failed to update Company Details', 'error'))
    dispatch(setLoading(false))
  }
}

export const createCompany = (formData: any) => async (dispatch: any) => {
  dispatch(updateActivityTime())
  if (!isTokenValid()) return

  const {
    name,
    currency,
    description,
    email,
    phone,
    street,
    city,
    state,
    country,
    post_code,
    business_number,
  } = formData

  const body = JSON.stringify({
    name,
    currency,
    description,
    email,
    phone,
    address: {
      street,
      city,
      state,
      country,
      post_code,
    },
    business_number,
  })

  try {
    const res = await axios.post(
      WORLDESK_URL + '/management/api/company/',
      body,
      header_config
    )
    dispatch({ type: ADD_COMPANY, payload: res.data })
    //console.log(res);
    dispatch({
      type: CREATE_COMPANY,
      payload: res.data,
    })

    dispatch(push('/settings/integrations'))
  } catch (err: any) {
    if (err && err.response && err.response.data && err.response.data.errors) {
      const { errors } = err.response.data

      if (errors && errors.Currency) {
        errors.Currency.forEach((err: any) => dispatch(notify(err, 'error')))
      } else if (errors && errors['Address.Country']) {
        errors['Address.Country'].forEach((err: any) =>
          dispatch(notify(err, 'error'))
        )
      } else if (errors && errors.Email) {
        errors.Email.forEach((err: any) => dispatch(notify(err, 'error')))
      }
    }
    dispatch(setLoading(false))
    dispatch(notify('Failed to create Company', 'error'))
  }
}

export const setEdit = (isEdit: boolean) => (dispatch: any) => {
  dispatch({
    type: TOGGLE_EDIT,
    payload: isEdit,
  })
}

export const setLoading = (loading: boolean) => (dispatch: any) => {
  dispatch({ type: TOGGLE_COMPANY_LOADING, payload: loading })
}
