import { LockratesStateI } from 'types/lockrates'
import {
  GET_LOCKRATES,
  GET_LOCKRATE_DETAILS,
  CREATE_LOCKRATE_SUCCESS,
  CREATE_LOCKRATE_REQUEST,
  CREATE_LOCKRATE_LOADING,
  CLEAR_LOCKRATE_DETAILS,
  CREATE_LOCKRATE_FAILURE,
  CLEAR_LOCKRATES,
  GET_LOCKRATE_QUOTE,
  CLEAR_LOCKRATE_QUOTE,
  GET_NONE_CONVERSION_DATES,
  GET_LOCKRATE_QUOTE_ERROR,
  SET_LOCKRATE_PARAMS,
  GET_LOCKRATES_ERROR,
  INVALIDATE_LOCKRATE_QUOTE,
  CLEAR_ITEMS,
  CLEAR_STORE,
  SET_LOCKRATE_PAGES_FILTER,
  GET_LOCKRATE_GUIDELINES_REQUEST,
  GET_LOCKRATE_GUIDELINES_SUCCESS,
  GET_LOCKRATE_GUIDELINES_FAILURE,
} from '../actions/types'

const initialState: LockratesStateI = {
  pages: 1,
  page: 1,
  lockrates: [],
  itemsLength: 0,
  table_loading: true,
  loading: false,
  saveItemLoading: false,
  quoteloading: false,
  quoteError: false,
  quoteErrorMessage: '',
  detailsLoading: true,
  isError: false,
  errorMessage: '',
  createLockrate: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    errorMessage: '',
  },
  isLockRateQuoteExpired: false,
  lockrate_details: {
    id: '',
    friendly_id: '',
    currency_provider: '',
    state: '',
    status: '',
    created_by: '',
    sell_currency: '',
    buy_currency: '',
    rate: 0,
    amount: 0,
    fixed_side: '',
    created_at: '',
    scheduled_at: '',
    updated_at: '',
    reason: '',
    name: '',
    buy_amount: 0,
    sell_amount: 0,
    deposit_required: false,
    deposit_amount: 0,
    deposit_currency: '',
    reference: '',
    error: '',
  },
  quote: {
    buy_currency: '',
    sell_currency: '',
    deposit_amount: '',
    deposit_currency: '',
    buy_amount: '',
    sell_amount: '',
    fixed_side: '',
    quote_id: '',
    quote_expires: '',
    value_date: '',
    rate: '',
  },
  non_conversion_dates: {
    base_currency: '',
    quote_currency: '',
    dates: [],
  },
  result: {
    friendly_id: '',
  },
  lock_rate_params: {
    name: '',
    buy_currency: 'EUR',
    sell_currency: 'USD',
    buy_amount: '',
    sell_amount: '',
    currency_provider: 'cambridge',
    fixed_side: 'buy',
    reason_id: 34,
    scheduled_at: new Date(),
    bill_id: '',
  },
  counterIncrement: 0,
  lockrateGuidelines: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    payment_currencies: [],
    settlement_currencies: [],
  },
}

const lockrates = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_LOCKRATE_PAGES_FILTER:
      return {
        ...state,

        page: payload,
      }
    case GET_LOCKRATES:
      return {
        ...state,
        lockrates: payload.items,
        itemsLength: payload.items.length,
        pages: payload.total_pages,
        table_loading: false,
        loading: false,
      }
    case GET_LOCKRATES_ERROR:
      return {
        ...state,
        loading: false,
        table_loading: false,
        saveItemLoading: false,
      }
    case GET_LOCKRATE_DETAILS:
      return {
        ...state,
        lockrate_details: payload,
        lockrates: state.lockrates.find(item => item.id === payload.id)
          ? state.lockrates.map(item => {
              if (payload.id === item.id) {
                return {
                  ...item,

                  ...payload,
                }
              } else {
                return item
              }
            })
          : [payload, ...state.lockrates],
        loading: false,
        detailsLoading: false,
      }
    case CREATE_LOCKRATE_REQUEST:
      return {
        ...state,

        createLockrate: {
          ...state.createLockrate,

          isFetching: true,
          isFetched: false,
          isFailed: false,
          errorMessage: '',
        },
      }
    case CREATE_LOCKRATE_SUCCESS:
      return {
        ...state,
        result: payload,
        loading: false,
        lockrates: [payload, ...state.lockrates],
        isError: false,
        errorMessage: '',
        saveItemLoading: false,
        createLockrate: {
          ...state.createLockrate,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          errorMessage: '',
        },
      }
    case CREATE_LOCKRATE_LOADING:
      return {
        ...state,
        loading: payload,
        saveItemLoading: payload,
        errorMessage: '',
      }
    case CREATE_LOCKRATE_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        errorMessage: payload.errorMessage,
        saveItemLoading: false,
        lockrates: payload.id ? [payload, ...state.lockrates] : state.lockrates,
        createLockrate: {
          ...state.createLockrate,

          isFetching: false,
          isFetched: true,
          isFailed: true,
          errorMessage: payload.errorMessage,
        },
      }
    case GET_LOCKRATE_QUOTE_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
        quoteloading: false,
        quoteError: true,
        quoteErrorMessage: payload,
      }
    case GET_LOCKRATE_QUOTE:
      return {
        ...state,
        quote: payload,
        loading: false,
        quoteloading: false,
        quoteError: false,
        quoteErrorMessage: '',
        isError: false,
        isLockRateQuoteExpired: false,
        counterIncrement:
          // @ts-ignore
          (Date.parse(payload.quote_expires) - Date.parse(new Date())) / 100000,
      }
    case INVALIDATE_LOCKRATE_QUOTE:
      return {
        ...state,
        isLockRateQuoteExpired: true,
      }
    case GET_NONE_CONVERSION_DATES:
      return {
        ...state,
        non_conversion_dates: payload,
      }
    case SET_LOCKRATE_PARAMS:
      return {
        ...state,
        lock_rate_params: { ...state.lock_rate_params, ...payload },
      }
    case GET_LOCKRATE_GUIDELINES_REQUEST:
      return {
        ...state,

        lockrateGuidelines: {
          ...state.lockrateGuidelines,

          isFetching: true,
          isFailed: false,
        },
      }
    case GET_LOCKRATE_GUIDELINES_SUCCESS:
      return {
        ...state,

        lockrateGuidelines: {
          ...state.lockrateGuidelines,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          ...payload,
          // settlement_currencies: ['EUR', 'AUD'],
        },
      }
    case GET_LOCKRATE_GUIDELINES_FAILURE:
      return {
        ...state,

        lockrateGuidelines: {
          ...state.lockrateGuidelines,

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    case CLEAR_LOCKRATES:
      return {
        ...state,
        lockrates: [],
        table_loading: true,
        lockrateGuidelines: state.lockrateGuidelines,
      }
    case CLEAR_ITEMS:
      return {
        ...state,
        lockrates: [],
        table_loading: true,
        lockrateGuidelines: state.lockrateGuidelines,
      }
    case CLEAR_LOCKRATE_DETAILS:
      return {
        ...state,
        detailsLoading: true,
        lockrate_details: {
          id: '',
          friendly_id: '',
          currency_provider: '',
          state: '',
          status: '',
          created_by: '',
          sell_currency: '',
          buy_currency: '',
          rate: 0,
          amount: 0,
          fixed_side: '',
          created_at: '',
          scheduled_at: '',
          updated_at: '',
          reason: '',
        },
        lockrateGuidelines: state.lockrateGuidelines,
      }
    case CLEAR_LOCKRATE_QUOTE:
      return {
        ...state,
        quote: {
          buy_currency: '',
          sell_currency: '',
          deposit_amount: '',
          deposit_currency: '',
          buy_amount: '',
          sell_amount: '',
          fixed_side: '',
          quote_id: '',
          quote_expires: '',
          value_date: '',
          rate: '',
        },
        lockrateGuidelines: state.lockrateGuidelines,
      }
    case CLEAR_STORE:
      return {
        ...initialState,
        lockrateGuidelines: state.lockrateGuidelines,
      }
    default:
      return state
  }
}

export default lockrates
