import getENV from '../env'
export const WORLDESK_URL = getENV().WORLDESK_GATEWAY

export const header_config = {
  headers: {
    'Content-Type': 'application/json',
    'x-client-Id': getENV().APPLICATION_CLIENT_ID,
  },
}
export const header_config_withCredentials = {
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'x-client-Id': getENV().APPLICATION_CLIENT_ID,
  },
  withCredentials: true,
}
