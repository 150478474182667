import axios from 'axios'
import { GET_CAMBRIDGE_WALLETS, GET_CAMBRIDGE_SETTLEMENTS } from './types'

import { WORLDESK_URL, header_config } from '../api/config'
import { notify } from './notifications'

export const getCambridgeWallets =
  (company_id: string) => async (dispatch: any) => {
    try {
      const body = JSON.stringify({
        currencies: [],
      })

      const res = await axios.post(
        WORLDESK_URL +
          `/cambridge/api/accounts/wallets?companyid=${encodeURIComponent(
            company_id
          )}`,
        body,
        header_config
      )

      dispatch({
        type: GET_CAMBRIDGE_WALLETS,
        payload: res.data,
      })
    } catch (err) {
      console.log(err)
      dispatch(notify('Failed to get List of Corpay Wallets', 'error'))
    }
  }

export const getCambridgeSettlements =
  (company_id: string) => async (dispatch: any) => {
    try {
      const body = JSON.stringify({
        currencies: null,
      })

      const res = await axios.post(
        WORLDESK_URL +
          `/cambridge/api/Accounts/settlements?companyid=${encodeURIComponent(
            company_id
          )}`,
        body,
        header_config
      )

      dispatch({
        type: GET_CAMBRIDGE_SETTLEMENTS,
        payload: res.data,
      })
    } catch (err) {
      dispatch(notify('Failed to get List of Corpay Settlements', 'error'))
    }
  }
