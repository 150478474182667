import {
  GET_BALANCES,
  CLEAR_BALANCES,
  GET_BALANCES_ERROR,
  SET_BALANCES_LOADING,
} from '../actions/types'

const initialState = {
  balances: [],
  loading: true,
}

const balances = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case GET_BALANCES:
      return {
        ...state,
        balances: payload,
        loading: false,
      }
    case GET_BALANCES_ERROR:
      return {
        ...state,
        loading: false,
      }
    case CLEAR_BALANCES:
      return {
        ...state,
        loading: true,
        balances: [],
      }
    case SET_BALANCES_LOADING:
      return {
        ...state,
        loading: payload,
      }
    default:
      return state
  }
}

export default balances
