import {
  SET_SESSION_EXPIRATION,
  SET_SESSION_IDLE,
  SET_SESSION_LAST_ACTIVITY_TIME,
} from '../actions/types'

const initialState = {
  session_expired: false,
  session_idle: false,
  last_activity_time: new Date(),
}

const session = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_SESSION_EXPIRATION:
      return {
        ...state,
        session_expired: payload,
      }
    case SET_SESSION_IDLE:
      return {
        ...state,
        session_idle: payload,
      }
    case SET_SESSION_LAST_ACTIVITY_TIME:
      return {
        ...state,
        last_activity_time: new Date(),
      }
    default:
      return state
  }
}
export default session
