import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getPingPongURL, getOfxURL } from '../../actions/currecyProviders'

import {
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  DarkMode,
  useDisclosure,
  Spinner,
} from '@chakra-ui/react'

const Reauthenticate = ({ onSkip }: { onSkip: any }) => {
  const [redirecting, setRedirecting] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure({
    defaultIsOpen: Boolean(
      Number(localStorage.getItem('reauthenticateNeeded'))
    ),
  })
  const { company_details } = useSelector((state: any) => state.company)
  const { currentProviderSelected } = useSelector(
    (state: any) => state.currencyProvider
  )
  const { isAuthenticated } = useSelector((state: any) => state.identity)

  const dispatch = useDispatch()

  const onClick = () => {
    if (currentProviderSelected === 'pingPong') {
      dispatch(getPingPongURL(company_details.id))
    } else if (currentProviderSelected === 'ofx') {
      dispatch(getOfxURL(company_details.id, true))
    }
    setRedirecting(true)
    localStorage.setItem('reauthenticateNeeded', '0')
  }

  useEffect(() => {
    function handleModal() {
      if (Boolean(Number(localStorage.getItem('reauthenticateNeeded')))) {
        onOpen()
      } else {
        onClose()
      }
    }

    window.addEventListener('storage', handleModal)

    return () => {
      window.removeEventListener('storage', handleModal)
    }
  }, [])

  if (!isAuthenticated) {
    return null
  }
  // if (!Boolean(Number(localStorage.getItem('reauthenticateNeeded')))) {
  //   return null
  // }

  if (
    currentProviderSelected === 'cambridge' ||
    currentProviderSelected === ''
  ) {
    return null
  }

  const close = () => {
    onSkip()
    localStorage.setItem('reauthenticateNeeded', '0')
    onClose()
  }

  return (
    <DarkMode>
      <Modal
        size='sm'
        isCentered
        isOpen={isOpen}
        closeOnOverlayClick={false}
        blockScrollOnMount={false}
        closeOnEsc={false}
        trapFocus={false}
        onClose={() => false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader id='modal-modal-title'>Attention</ModalHeader>
          <ModalBody>
            <Text>
              Your token has expired, please click here to re authenticate:
            </Text>
            {redirecting ? (
              <Flex alignItems='center' fontSize='sm' mt='10px'>
                Please wait, redirecting to OFX <Spinner size='sm' ml='8px' />
              </Flex>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button
              variant='ghost'
              mr={3}
              color='brand.200'
              fontSize='sm'
              onClick={close}
              disabled={redirecting}
              height='buttonHeight'
            >
              Skip for now
            </Button>
            <Button
              colorScheme='blue'
              mr={3}
              onClick={onClick}
              disabled={redirecting}
              height='buttonHeight'
            >
              Authenticate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </DarkMode>
  )
}

export default Reauthenticate
