import { SET_INITIALIZATION } from '../actions/types'

const initialState = {
  isInit: true,
}

const init = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_INITIALIZATION:
      return {
        ...state,
        isInit: payload,
      }
    default:
      return state
  }
}
export default init
