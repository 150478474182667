import {
  GET_CAMBRIDGE_WALLETS,
  GET_CAMBRIDGE_SETTLEMENTS,
} from '../actions/types'

import _ from 'lodash'

const initialState = {
  wallets: [],
  settlements: [],
}

const cambridgeAccounts = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case GET_CAMBRIDGE_WALLETS:
      return {
        ...state,

        wallets: payload,
      }
    case GET_CAMBRIDGE_SETTLEMENTS:
      return {
        ...state,
        settlements: _.orderBy(payload, ['type'], ['asc']),
        // for testing multiple achs
        // settlements: _.orderBy([{
        //   id: '1234',
        //   type: 'ach',
        //   bank_name: 'temo',
        //   currency: 'USD'
        // }, ...payload], ['type'], ['acs'])
      }
    default:
      return state
  }
}

export default cambridgeAccounts
