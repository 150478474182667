import {
  SET_SESSION_EXPIRATION,
  SET_SESSION_IDLE,
  SET_SESSION_LAST_ACTIVITY_TIME,
} from './types'

export const setSessionExpiration = (session: boolean) => (dispatch: any) => {
  dispatch({
    type: SET_SESSION_EXPIRATION,
    payload: session,
  })
}

export const setSessionIdle = (session: boolean) => (dispatch: any) => {
  dispatch({
    type: SET_SESSION_IDLE,
    payload: session,
  })
}

export const updateActivityTime = () => (dispatch: any) => {
  dispatch({
    type: SET_SESSION_LAST_ACTIVITY_TIME,
  })
}
