import { createRoot } from 'react-dom/client'
import App from './App'
import reducers from './reducers'
import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import {
  ReduxRouter,
  createRouterMiddleware,
} from '@lagunovsky/redux-react-router'
import { combineReducers } from 'redux'
import { createBrowserHistory } from 'history'

import { configureStore } from '@reduxjs/toolkit'

const history = createBrowserHistory()
const routerMiddleware = createRouterMiddleware(history)
const rootReducer = combineReducers(reducers())

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false }).prepend(
      routerMiddleware
    ),
})

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)

  root.render(
    <Provider store={store}>
      <ReduxRouter history={history}>
        <App />
      </ReduxRouter>
    </Provider>
  )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
