import moment from 'moment'

const isTokenValid = () => {
  let expirationTime = Number(localStorage.getItem('expiration_time'))

  if (expirationTime && moment.unix(expirationTime) > moment()) {
    return true
  } else {
    return false
  }
}

export default isTokenValid
