import { ConversionsStateI } from 'types/conversions'
import {
  GET_CONVERSIONS,
  GET_NONE_CONVERSION_DTS,
  GET_CONVERSION_DETAILS,
  CREATE_CONVERSION,
  CREATE_CONVERSION_ERROR,
  CREATE_CONVERSION_LOADING,
  CLEAR_CONVERSION_DETAILS,
  CLEAR_CONVERSIONS,
  GET_QUOTE,
  GET_QUOTE_ERROR,
  CLEAR_QUOTE,
  SET_QUOTE_LOADING,
  GET_CONVERSIONS_ERROR,
  INVALIDATE_QUOTE,
  CLEAR_ITEMS,
  CLEAR_STORE,
  DELETE_TABLE_ROW_REQUEST,
  DELETE_TABLE_ROW_SUCCESS,
  DELETE_TABLE_ROW_FAILURE,
  SET_PAGES_FILTER,
} from '../actions/types'

const initialState: ConversionsStateI = {
  pages: 1,
  page: 1,
  conversions: [],
  itemsLength: 0,
  detailsLoading: true,
  loading: true,
  saveItemLoading: false,
  quoteloading: false,
  quoteError: false,
  quoteErrorMessage: '',
  isError: false,
  errorMessage: '',
  isQuoteExpired: false,
  conversion_details: {
    id: '',
    friendly_id: '',
    reference: '',
    state: '',
    status: '',
    name: '',
    created_by: '',
    sell_currency: '',
    buy_currency: '',
    rate: 0,
    amount: 0,
    sell_amount: 0,
    buy_amount: 0,
    fixed_side: '',
    fund_method: '',
    created_at: '',
    scheduled_at: '',
    updated_at: '',
    fee_amount: 0,
    error: '',
    reason_id: '',
    payments: [],
  },
  reasons: {},
  quote: {
    buy_currency: '',
    sell_currency: '',
    buy_amount: '',
    sell_amount: '',
    fixed_side: '',
    quote_id: '',
    quote_expires: '',
    value_date: '',
    rate: '',
  },
  result: {
    id: '',
    state: '',
    friendly_id: '',
  },
  non_conversion_dates: {},
  counterIncrement: 0,
}

const conversions = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_PAGES_FILTER:
      return {
        ...state,

        page: payload,
      }
    case DELETE_TABLE_ROW_REQUEST:
      return {
        ...state,

        conversions: state.conversions.map(item => {
          if (item.id === payload.id) {
            return {
              ...item,

              isDeleting: true,
              isDeleted: false,
              isDeleteFailed: false,
            }
          } else {
            return item
          }
        }),
      }
    case DELETE_TABLE_ROW_SUCCESS:
      return {
        ...state,

        conversions: state.conversions.filter(item => item.id !== payload.id),
      }
    case DELETE_TABLE_ROW_FAILURE:
      return {
        ...state,

        conversions: state.conversions.map(item => {
          if (item.id === payload.id) {
            return {
              ...item,

              isDeleting: false,
              isDeleted: false,
              isDeleteFailed: true,
            }
          } else {
            return item
          }
        }),
      }
    case GET_CONVERSIONS:
      return {
        ...state,
        conversions: payload.items,
        itemsLength: payload.items.length,
        pages: payload.total_pages === 0 ? 1 : payload.total_pages,
        loading: false,
      }
    case GET_CONVERSIONS_ERROR:
      return {
        ...state,
        loading: false,
        saveItemLoading: false,
      }
    case GET_CONVERSION_DETAILS:
      return {
        ...state,
        loading: false,
        conversion_details: payload,
        conversions: state.conversions.find(item => item.id === payload.id)
          ? state.conversions.map(item => {
              if (payload.id === item.id) {
                return {
                  ...item,

                  ...payload,
                }
              } else {
                return item
              }
            })
          : [payload, ...state.conversions],
        detailsLoading: false,
      }
    case CREATE_CONVERSION:
      return {
        ...state,
        result: payload,
        conversions: [payload, ...state.conversions],
        loading: false,
        isError: false,
        errorMessage: '',
        saveItemLoading: false,
      }
    case CREATE_CONVERSION_LOADING:
      return {
        ...state,
        saveItemLoading: payload,
        isError: false,
        errorMessage: '',
      }
    case SET_QUOTE_LOADING:
      return {
        ...state,
        quoteloading: true,
      }
    case CREATE_CONVERSION_ERROR:
      return {
        ...state,
        loading: false,
        isError: true,
        errorMessage: payload.errorMessage,
        saveItemLoading: false,
        conversions: payload.id
          ? [payload, ...state.conversions]
          : state.conversions,
      }
    case GET_QUOTE:
      return {
        ...state,
        quote: payload,
        quoteloading: false,
        quoteError: false,
        quoteErrorMessage: '',
        isQuoteExpired: false,
        counterIncrement:
          (Date.parse(payload.quote_expires) -
            Date.parse(new Date().toString())) /
          100000,
      }
    case INVALIDATE_QUOTE:
      return {
        ...state,
        isQuoteExpired: true,
      }
    case GET_QUOTE_ERROR:
      return {
        ...state,
        quoteloading: false,
        quoteError: true,
        quoteErrorMessage: payload,
      }
    case GET_NONE_CONVERSION_DTS:
      return {
        ...state,
        non_conversion_dates: {
          ...state.non_conversion_dates,
          [`${payload.base_currency}${payload.quote_currency}`]: payload.dates,
        },
      }

    case CLEAR_CONVERSIONS:
      return {
        ...state,
        conversions: [],
        non_conversion_dates: {},
        loading: true,
      }
    case CLEAR_ITEMS:
      return {
        ...state,
        conversions: [],
        loading: true,
      }
    case CLEAR_CONVERSION_DETAILS:
      return {
        ...state,
        conversion_details: {},
        detailsLoading: true,
      }
    case CLEAR_QUOTE:
      return {
        ...state,
        quote: {
          buy_currency: '',
          sell_currency: '',
          buy_amount: '',
          sell_amount: '',
          fixed_side: '',
          quote_id: '',
          quote_expires: '',
          value_date: '',
          rate: '',
        },
      }
    case CLEAR_STORE:
      return { ...initialState, ...state.conversions }
    default:
      return state
  }
}

export default conversions
