export const buttonStyles = {
  components: {
    Button: {
      variants: {
        'no-hover': {
          _hover: {
            boxShadow: 'none',
          },
        },
        'transparent-with-icon': {
          bg: 'transparent',
          fontWeight: 'bold',
          borderRadius: 'inherit',
          cursor: 'pointer',
          _active: {
            bg: 'transparent',
            transform: 'none',
            borderColor: 'transparent',
          },
          _focus: {
            boxShadow: 'none',
          },
          _hover: {
            boxShadow: 'none',
          },
        },
        brand: {
          fontSize: '14px',
          height: '35px',
          bg: 'brand.200',
          color: '#fff',
          _hover: {
            bg: 'brand.300',
          },
          _active: {
            bg: 'brand.400',
          },
          _focus: {},
        },
        outlineWhite: {
          bg: 'transparent',
          color: '#fff',
          borderColor: 'white',
          borderWidth: '1px',
          _hover: {
            bg: 'whiteAlpha.100',
          },
          _active: {
            bg: 'whiteAlpha.200',
          },
          _focus: {},
        },
      },
      baseStyle: {
        borderRadius: '12px',
        _active: 'none',
        _focus: {},
      },
    },
  },
}
