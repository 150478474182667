import { Icon } from '@chakra-ui/react'
import { FaExclamationCircle } from 'react-icons/fa'
import { Text, Flex } from '@chakra-ui/react'

import Card from 'components/baseComponents/Card/Card'
import CardBody from 'components/baseComponents/Card/CardBody'
import CardHeader from 'components/baseComponents/Card/CardHeader'

const ErrorFallBack = () => {
  return (
    <>
      <Card
        p='16px'
        maxH={{ md: '410px' }}
        maxW={{ sm: '100%', lg: '444px' }}
        m='80px auto'
      >
        <CardHeader
          p='12px 5px'
          mb='12px'
          flexDirection='column'
          alignItems='center'
        >
          <Icon as={FaExclamationCircle} w={30} h={30} color='red.500' />
          <Text
            fontSize='lg'
            color='#fff'
            fontWeight='bold'
            textAlign='center'
            w='100%'
            mt='20px'
          >
            Oops! Something went wrong.
          </Text>
        </CardHeader>
        <CardBody px='5px'>
          <Flex direction='column' textAlign='center' w='100%'>
            <Text fontSize='sm' color={'gray.400'} fontWeight='400' mb='15px'>
              We apologize for your incovenience.
            </Text>
            <Text fontSize='sm' color={'gray.400'} fontWeight='400' mb='15px'>
              Please manually refresh the page.
            </Text>
          </Flex>
        </CardBody>
      </Card>
    </>
  )
}

export default ErrorFallBack
