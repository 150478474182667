// @ts-nocheck
import axios from 'axios'
import { datadogLogs } from '@datadog/browser-logs'

const setAuthToken = token => {
  if (token) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['Authorization']
  }
}

axios.interceptors.request.use(req => {
  if (Boolean(Number(localStorage.getItem('reauthenticateNeeded')))) {
    return false
  }
  return req
})

const getSensitivePayloadData = (data, status) => {
  if (data) {
    const responseData = JSON.parse(data)
    let sensitivePayloadData = responseData
    if (responseData.password) {
      sensitivePayloadData = {
        ...JSON.parse(data),
        password: '***',
      }
    }
    if (responseData.old_password) {
      sensitivePayloadData = {
        ...JSON.parse(data),
        old_password: '***',
      }
    }
    if (responseData.new_password) {
      sensitivePayloadData = {
        ...JSON.parse(data),
        new_password: '***',
      }
    }
    if (responseData.client_code) {
      sensitivePayloadData = {
        ...JSON.parse(data),
        client_code: '***',
      }
    }
    if (responseData.client_key) {
      sensitivePayloadData = {
        ...JSON.parse(data),
        client_key: '***',
      }
    }
    return JSON.stringify(sensitivePayloadData)
  }
  return status
}

const getMessage = data => {
  if (data) {
    if (typeof data === 'string') {
      return data
    }
    if (data.error) {
      if (typeof data.error === 'string') {
        return data.error
      }
    }
    if (data.message) {
      if (typeof data.message === 'string') {
        return data.message
      }
    }
    if (data.detail) {
      if (typeof data.detail === 'string') {
        return data.detail
      }
    }
  }
  return 'api error from client'
}

axios.interceptors.response.use(
  response => response,
  error => {
    const companyId = localStorage.getItem('companyId')
    const response = error.response

    const isAllowedEndpointsToReport = () => {
      if (
        response.config.url.includes('guard/api/diagnostics') ||
        (response.config.url.includes('identity/api/account/login') &&
          error.response.status === 403)
      ) {
        return false
      }
      return true
    }

    if (isAllowedEndpointsToReport() && companyId) {
      const sensitivePayloadData = getSensitivePayloadData(
        response.config.data,
        response.status
      )

      const body = {
        request_url: response.config.url || '',
        correlation_id:
          response.headers['x-correlation-id'] || 'no correlation',
        RequestId: response.headers['x-correlation-id'] || 'no correlation',
        status_code: response.status,
        response: JSON.stringify(response.data).includes(
          'Web App - Unavailable'
        )
          ? 'Web App - Unavailable'
          : JSON.stringify(response.data),
        payload: sensitivePayloadData,
      }

      datadogLogs.logger.error(getMessage(response.data), body)
    }
    if (!error.response) {
      console.error('NETWORK ERROR')
    } else {
      if (
        error.response.status === 491 &&
        !Boolean(Number(localStorage.getItem('reauthenticateNeeded')))
      ) {
        localStorage.setItem('reauthenticateNeeded', 1, {
          sameSite: 'strict',
          secure: true,
        })
        window.dispatchEvent(new Event('storage'))
        // window.location.reload()
      }
      return Promise.reject(error)
    }
  }
)

export default setAuthToken
