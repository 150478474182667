const PanelContainer = {
  baseStyle: {
    p: {
      sm: 0,
      md: '0 15px 30px',
    },
    // minHeight: "calc(100vh - 150px)",
  },
}

export const PanelContainerComponent = {
  components: {
    PanelContainer,
  },
}
