import {
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  ONBOARD_LOADING,
  EMAIL_VERIFY_SUCCESS,
  EMAIL_VERIFY_FAIL,
  PHONE_VERIFY_SUCCESS,
  PHONE_VERIFY_FAIL,
  SET_EMAIL,
  CLEAR_ONBOARDING,
  RECAPTCHA_FAIL_REGISTER,
  INITIALTE_PHONE_VERIFY_REQUEST,
  INITIALTE_PHONE_VERIFY_SUCCESS,
  INITIALTE_PHONE_VERIFY_FAILURE,
} from '../actions/types'

import { OnboardingAction, OnboardingState } from 'types/onboarding'

const initialState: OnboardingState = {
  isUserCreated: false,
  userEmail: '',
  userPhone: '',
  email_confirmed: false,
  phone_confirmed: false,
  loading: false,
  recaptchaFail: '',
  error: {
    message: '',
  },
  phoneVerifyState: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
  },
}

const onboarding = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        isUserCreated: true,
        error: {
          ...state.error,

          message: '',
        },
      }
    case USER_CREATE_FAIL:
      return {
        ...state,
        loading: false,
        isUserCreated: false,
        error: {
          ...state.error,

          message: payload,
        },
      }
    case EMAIL_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        email_confirmed: true,
      }
    case EMAIL_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        email_confirmed: false,
      }
    case PHONE_VERIFY_SUCCESS:
      return {
        ...state,
        loading: false,
        phone_confirmed: true,
      }
    case PHONE_VERIFY_FAIL:
      return {
        ...state,
        loading: false,
        phone_confirmed: false,
      }
    case ONBOARD_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case SET_EMAIL:
      return {
        ...state,
        userEmail: payload,
      }
    case CLEAR_ONBOARDING:
      return {
        ...state,
        isUserCreated: false,
        userEmail: '',
        userPhone: '',
        email_confirmed: false,
        phone_confirmed: false,
        loading: false,
      }
    case RECAPTCHA_FAIL_REGISTER:
      return {
        ...state,
        recaptchaFail: payload,
      }
    case INITIALTE_PHONE_VERIFY_REQUEST:
      return {
        ...state,

        phoneVerifyState: {
          ...state.phoneVerifyState,

          isFetching: true,
          isFetched: false,
          isFailed: false,
        },
      }
    case INITIALTE_PHONE_VERIFY_SUCCESS:
      return {
        ...state,

        phoneVerifyState: {
          ...state.phoneVerifyState,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          data: payload,
        },
      }
    case INITIALTE_PHONE_VERIFY_FAILURE:
      return {
        ...state,

        phoneVerifyState: {
          ...state.phoneVerifyState,

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    default:
      return state
  }
}

export default onboarding
