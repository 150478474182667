const Card = {
  baseStyle: (props: any) => {
    return {
      display: 'flex',
      flexShrink: 0,
      flexDirection: 'column',
      backdropFilter: 'blur(120px)',
      width: '100%',
      borderRadius: '20px',
      overflowY: 'hidden',
      bg: props.colorMode === 'dark' ? 'grayColor' : 'white',
      height: '100%',
      backgroundClip: 'border-box',
      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    }
  },
}

export const CardComponent = {
  components: {
    Card,
  },
}
