import { BillsState } from 'types/bills'
import {
  GET_BILLS,
  GET_BILLS_ERROR,
  GET_BILL_DETAILS,
  GET_BILL_QUOTE,
  GET_VENDOR_DETAILS,
  SET_BILL_LOADING,
  CLEAR_BILLS,
  CLEAR_BILL_DETAILS,
  CLEAR_VENDOR_DETAILS,
  CLEAR_BILL_QUOTE,
  SET_UPCOMING_BILLS,
  CLEAR_UPCOMING_BILLS,
  CLEAR_STORE,
  UPDATE_VENDOR_SUCCESS,
  CREATE_PAYMENT_SUCCESS,
  CREATE_LOCKRATE_SUCCESS,
  CREATE_AUTOMATION_SUCCESS,
  SET_BILL_PAGES_FILTER,
} from '../actions/types'

const initialState: BillsState = {
  pages: 1,
  page: 1,
  bills: [],
  upcoming_bills: [],
  loading: true,
  detailsLoading: true,
  error: {
    statusCode: null,
    message: '',
  },
  bill_details: {
    id: '',
    friendly_id: '',
    state: '',
    name: '',
    currency: '',
    amount: '',
    amount_pending: '',
    balance: '',
    payable_base_currency: '',
    balance_base_currency: '',
    payable: '',
    source: '',
    currency_provider: '',
    created_at: '',
    due_at: '',
    updated_at: '',
    payments: [],
    lock_rates: [],
    conversion_automation: {
      id: '',
      friendly_id: '',
      buy_currency: '',
      sell_currency: '',
      fixed_side: '',
      amount: 0.0,
      operator: '',
      target_rate: 0,
      state: '',
      status: '',
      created_at: '',
      ends_at: '',
      reached_at: '',
      name: '',
      currency_provider: '',
      conversion_id: '',
      buy_amount: 0,
      sell_amount: 0,
      source_rate: 0,
      current_rate: 0,
      error: '',
    },
    vendor: {
      id: '',
      reference: '',
      currency: '',
      display_name: '',
      beneficiary_id: '',
    },
  },
  vendor_details: {
    id: '',
    reference: '',
    currency: '',
    display_name: '',
    first_name: '',
    last_name: '',
    company_name: '',
    active: true,
    created_at: '',
    updated_at: '',
  },
  quote: {
    buy_currency: '',
    sell_currency: '',
    buy_amount: '',
    sell_amount: '',
    fixed_side: '',
    quote_id: '',
    quote_expires: '',
    value_date: '',
    rate: '',
  },
}

const bills = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_BILL_PAGES_FILTER:
      return {
        ...state,

        page: payload,
      }
    case CREATE_AUTOMATION_SUCCESS:
      return {
        ...state,

        bill_details: {
          ...state.bill_details,

          conversion_automation: payload,
        },
      }
    case CREATE_LOCKRATE_SUCCESS:
      return {
        ...state,

        bill_details: {
          ...state.bill_details,

          lock_rates: payload.bill_id
            ? [...state.bill_details.lock_rates, payload]
            : state.bill_details.lock_rates,
        },
      }
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,

        bill_details: {
          ...state.bill_details,

          payments: payload.bill_id
            ? [...state.bill_details.payments, payload]
            : state.bill_details.payments,
        },
      }
    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,

        bill_details: {
          ...state.bill_details,

          vendor: {
            ...state.bill_details.vendor,

            beneficiary_id: payload,
          },
        },
      }
    case GET_BILLS:
      return {
        ...state,
        bills: payload.items,
        itemsLength: payload.items.length,
        pages: payload.total_pages,
        loading: false,
        error: {
          statusCode: null,
          message: '',
        },
      }
    case GET_BILLS_ERROR:
      return {
        ...state,
        loading: false,
        error: {
          statusCode: payload.status,
          message: payload.message,
        },
      }
    case GET_BILL_DETAILS:
      return {
        ...state,
        bill_details: payload,
        bills: state.bills.find(item => item.id === payload.id)
          ? state.bills.map(item => {
              if (payload.id === item.id) {
                return {
                  ...item,

                  ...payload,
                }
              } else {
                return item
              }
            })
          : [payload, ...state.bills],
        detailsLoading: false,
      }
    case GET_BILL_QUOTE:
      return {
        ...state,
        quote: payload,
      }
    case GET_VENDOR_DETAILS:
      return {
        ...state,
        vendor_details: payload,
      }
    case CLEAR_BILLS:
      return {
        ...state,
        bills: [],
        loading: true,
      }
    case CLEAR_BILL_QUOTE:
      return {
        ...state,
        quote: {
          buy_currency: '',
          sell_currency: '',
          buy_amount: '',
          sell_amount: '',
          fixed_side: '',
          quote_id: '',
          quote_expires: '',
          value_date: '',
          rate: '',
        },
      }
    case CLEAR_BILL_DETAILS:
      return {
        ...state,
        detailsLoading: true,
        bill_details: {
          id: '',
          friendly_id: '',
          state: '',
          name: '',
          currency: '',
          amount: '',
          amount_pending: '',
          balance: '',
          balance_base_currency: '',
          payable_base_currency: '',
          payable: '',
          source: '',
          currency_provider: '',
          created_at: '',
          due_at: '',
          updated_at: '',
          payments: [],
          lock_rates: [],
          conversion_automation: {
            id: '',
            friendly_id: '',
            buy_currency: '',
            sell_currency: '',
            fixed_side: '',
            amount: 0.0,
            operator: '',
            target_rate: 0,
            state: '',
            status: '',
            created_at: '',
            ends_at: '',
            reached_at: '',
            name: '',
            currency_provider: '',
            conversion_id: '',
          },
          vendor: {
            id: '',
            reference: '',
            currency: '',
            display_name: '',
          },
        },
      }
    case CLEAR_VENDOR_DETAILS:
      return {
        ...state,
        vendor_details: {
          id: '',
          reference: '',
          currency: '',
          display_name: '',
          first_name: '',
          last_name: '',
          company_name: '',
          active: true,
          created_at: '',
          updated_at: '',
        },
      }
    case SET_BILL_LOADING:
      return {
        ...state,
        loading: payload,
      }
    case SET_UPCOMING_BILLS:
      return {
        ...state,
        upcoming_bills: payload,
      }
    case CLEAR_UPCOMING_BILLS:
      return {
        ...state,
        upcoming_bills: [],
      }
    case CLEAR_STORE:
      return initialState

    default:
      return state
  }
}

export default bills
