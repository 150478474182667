import {
  GET_AUTOMATIONS,
  GET_AUTOMATION_DETAILS,
  GET_AUTOMATION_CURRENCIES,
  CREATE_AUTOMATION_REQUEST,
  CREATE_AUTOMATION_SUCCESS,
  CREATE_AUTOMATION_LOADING,
  CREATE_AUTOMATION_FAILURE,
  CLEAR_AUTOMATION_DETAILS,
  CLEAR_AUTOMATIONS,
  GET_AUTOMATION_QUOTE,
  CLEAR_AUTOMATION_QUOTE,
  UPDATE_AUTOMATION,
  GET_HISTORICAL_PRICES,
  FETCH_AUTOMATION_BILLS_REQUEST,
  FETCH_AUTOMATION_BILLS_SUCCESS,
  GET_AUTOMATIONS_ERROR,
  CLEAR_HISTORICAL_PRICES,
  CLEAR_ITEMS,
  CLEAR_STORE,
  DELETE_TABLE_ROW_REQUEST,
  DELETE_TABLE_ROW_SUCCESS,
  DELETE_TABLE_ROW_FAILURE,
  SET_AUTOMATIONS_PAGES_FILTER,
} from '../actions/types'

import { AutomationsState } from 'types/automations'

const initialState: AutomationsState = {
  pages: 1,
  page: 1,
  automations: [],
  itemsLength: 0,
  loading: true,
  saveItemLoading: false,
  detailsLoading: true,
  isError: false,
  errorMessage: '',
  createAutomation: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    errorMessage: '',
  },
  automation_details: {
    id: '',
    buy_currency: '',
    sell_currency: '',
    fixed_side: '',
    amount: '',
    operator: 'greaterThan',
    target_rate: '',
    source_rate: '',
    current_rate: '',
    markup: '',
    state: '',
    status: '',
    created_at: '',
    ends_at: '',
    name: '',
    reason_id: '',
    currency_provider: '',
    user_id: '',
    created_by_user: '',
    isDeleting: false,
    friendly_id: '',
    buy_amount: '',
    sell_amount: '',
    actual_rate: '',
    reached_at: '',
    conversion_id: '',
    error: '',
    bills: [],
  },
  currencies: [],
  quote: {
    buy_currency: '',
    sell_currency: '',
    buy_amount: '',
    sell_amount: '',
    fixed_side: '',
    quote_id: '',
    quote_expires: '',
    value_date: '',
    rate: '',
  },
  historical_prices: {},
  result: {
    id: '',
    friendly_id: '',
  },
  bills: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    items: [],
  },
}

const automations = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_AUTOMATIONS_PAGES_FILTER:
      return {
        ...state,

        page: payload,
      }
    case DELETE_TABLE_ROW_REQUEST:
      return {
        ...state,

        automations: state.automations.map(item => {
          if (item.id === payload.id) {
            return {
              ...item,

              isDeleting: true,
              isDeleted: false,
              isDeleteFailed: false,
            }
          } else {
            return item
          }
        }),
      }
    case DELETE_TABLE_ROW_SUCCESS:
      return {
        ...state,

        automations:
          payload.state === 'failed' || payload.state === 'canceled'
            ? state.automations.filter(item => item.id !== payload.id)
            : state.automations.map(item => {
                if (item.id === payload.id) {
                  return {
                    ...item,

                    isDeleting: false,
                    isDeleted: false,
                    isDeleteFailed: false,
                    state: 'canceled',
                  }
                } else {
                  return item
                }
              }),
      }
    case DELETE_TABLE_ROW_FAILURE:
      return {
        ...state,

        automations: state.automations.map(item => {
          if (item.id === payload.id) {
            return {
              ...item,

              isDeleting: false,
              isDeleted: false,
              isDeleteFailed: true,
            }
          } else {
            return item
          }
        }),
      }
    case GET_AUTOMATIONS:
      return {
        ...state,
        automations: payload.items,
        itemsLength: payload.items.length,
        pages: payload.total_pages === 0 ? 1 : payload.total_pages,
        loading: false,
      }
    case GET_AUTOMATIONS_ERROR:
      return {
        ...state,
        loading: false,
      }
    case GET_AUTOMATION_DETAILS:
      return {
        ...state,
        automation_details: payload,
        automations: state.automations.find(item => item.id === payload.id)
          ? state.automations.map(item => {
              if (payload.id === item.id) {
                return {
                  ...item,

                  ...payload,
                }
              } else {
                return item
              }
            })
          : [payload, ...state.automations],
        detailsLoading: false,
      }
    case FETCH_AUTOMATION_BILLS_REQUEST:
      return {
        ...state,

        bills: {
          ...state.bills,

          isFetching: true,
          isFetched: false,
        },
      }
    case FETCH_AUTOMATION_BILLS_SUCCESS:
      return {
        ...state,
        bills: {
          ...state.bills,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          items: payload,
        },
      }
    case GET_AUTOMATION_CURRENCIES:
      return {
        ...state,
        currencies: payload,
      }
    case CREATE_AUTOMATION_REQUEST:
      return {
        ...state,

        createAutomation: {
          ...state.createAutomation,

          isFetching: true,
          isFetched: false,
          isFailed: false,
          errorMessage: '',
        },
      }
    case CREATE_AUTOMATION_SUCCESS:
      return {
        ...state,
        result: payload,
        automations: [payload, ...state.automations],
        loading: false,
        isError: false,
        errorMessage: '',
        saveItemLoading: false,
        createAutomation: {
          ...state.createAutomation,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          errorMessage: '',
        },
      }
    case GET_AUTOMATION_QUOTE:
      return {
        ...state,
        quote: payload,
      }
    case UPDATE_AUTOMATION:
      return {
        ...state,
        loading: false,
        isError: false,
      }
    case GET_HISTORICAL_PRICES:
      return {
        ...state,
        historical_prices: payload,
      }
    case CLEAR_HISTORICAL_PRICES:
      return {
        ...state,
        historical_prices: {},
      }

    case CREATE_AUTOMATION_LOADING:
      return {
        ...state,
        saveItemLoading: payload,
        isError: false,
        errorMessage: '',
      }

    case CREATE_AUTOMATION_FAILURE:
      return {
        ...state,
        loading: false,
        isError: true,
        errorMessage: payload.errorMessage,
        saveItemLoading: false,
        automations: payload.id
          ? [payload, ...state.automations]
          : state.automations,
        createAutomation: {
          ...state.createAutomation,

          isFetching: false,
          isFetched: true,
          isFailed: true,
          errorMessage: payload.errorMessage,
        },
      }
    case CLEAR_AUTOMATIONS:
      return {
        ...state,
        automations: [],
        loading: true,
      }
    case CLEAR_ITEMS:
      return {
        ...state,
        automations: [],
        loading: true,
      }
    case CLEAR_AUTOMATION_DETAILS:
      return {
        ...state,
        detailsLoading: true,
        automation_details: {
          id: '',
          buy_currency: '',
          sell_currency: '',
          fixed_side: '',
          amount: '',
          operator: 'greaterThan',
          target_rate: '',
          source_rate: '',
          current_rate: '',
          markup: '',
          state: '',
          status: '',
          created_at: '',
          ends_at: '',
          name: '',
          reason_id: '',
          currency_provider: '',
          user_id: '',
          created_by_user: '',
          bills: [],
        },
        historical_prices: {},
      }
    case CLEAR_AUTOMATION_QUOTE:
      return {
        ...state,
        quote: {
          buy_currency: '',
          sell_currency: '',
          buy_amount: '',
          sell_amount: '',
          fixed_side: '',
          quote_id: '',
          quote_expires: '',
          value_date: '',
          rate: '',
        },
      }
    case CLEAR_STORE:
      return { ...initialState, ...state.automations }

    default:
      return state
  }
}

export default automations
