import { USERINFO, EXPIRED_PASSWORD } from '../actions/types'
import { UserInfoState, UserInfoAction } from 'types/userInfo'

const initialState: UserInfoState = {
  user_uuid: '',
  first_name: '',
  last_name: '',
  email: '',
  phone_number: '',
  email_confirmed: false,
  phone_confirmed: false,
  loading: true,
}

const userinfo = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case USERINFO:
      localStorage.setItem('email', payload.email)
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case EXPIRED_PASSWORD:
      return {
        ...state,
        email: payload,
      }
    default:
      return state
  }
}

export default userinfo
