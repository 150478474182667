import {
  CONNECT_QUICKBOOKS,
  CONNECT_QUICKBOOKS_ERROR,
  DISCONNECT_QUICKBOOKS,
  DISCONNECT_QUICKBOOKS_ERROR,
  CLEAR_QUICKBOOKS,
  LOADING_QUICKBOOKS,
  SET_QUICKBOOK_STATUS,
  UPDATE_VENDOR_REQUEST,
  UPDATE_VENDOR_SUCCESS,
  UPDATE_VENDOR_FAILURE,
  UPDATE_VENDOR_RESET,
} from '../actions/types'

const initialState = {
  isQuickBooksConnected: false,
  status: '',
  loading: true,
  isError: false,
  updateVendor: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    id: null,
  },
}

const quickbooks = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case UPDATE_VENDOR_RESET:
      return {
        ...state,

        updateVendor: {
          ...state.updateVendor,

          isFetching: false,
          isFetched: false,
          isFailed: false,
        },
      }
    case UPDATE_VENDOR_REQUEST:
      return {
        ...state,

        updateVendor: {
          ...state.updateVendor,

          isFetching: true,
          isFetched: false,
        },
      }
    case UPDATE_VENDOR_SUCCESS:
      return {
        ...state,

        updateVendor: {
          ...state.updateVendor,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          id: payload,
        },
      }
    case UPDATE_VENDOR_FAILURE:
      return {
        ...state,

        updateVendor: {
          ...state.updateVendor,

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    case CONNECT_QUICKBOOKS:
      return {
        ...state,
        isQuickBooksConnected: true,
        loading: false,
        isError: false,
      }
    case SET_QUICKBOOK_STATUS:
      return {
        ...state,
        status: payload,
      }
    case CONNECT_QUICKBOOKS_ERROR:
      return {
        ...state,
        isError: true,
        isQuickBooksConnected: false,
        loading: false,
      }
    case DISCONNECT_QUICKBOOKS_ERROR:
      return {
        ...state,
        isError: true,
        loading: false,
      }
    case LOADING_QUICKBOOKS:
      return {
        ...state,
        loading: true,
      }
    case CLEAR_QUICKBOOKS:
    case DISCONNECT_QUICKBOOKS:
      return {
        ...state,
        isQuickBooksConnected: false,
        isError: false,
        loading: false,
      }
    default:
      return state
  }
}

export default quickbooks
