import { notify } from './notifications'

export const checkErrorStatus = (err: any) => async (dispatch: any) => {
  if (err.response) {
    if (!err.response.data) return

    if (!err.response.status) return

    const { status } = err.response

    if (err.response.data && status === 500) {
      if (err.response.data.detail && err.response.data.detail !== '') {
        dispatch(notify(err.response.data.detail, 'error'))
      } else if (err.response.data.title && err.response.data.title !== '') {
        dispatch(notify(err.response.data.title, 'error'))
      }
    } else if (
      err.response.data.error &&
      status === 400 &&
      err.response.data.error !== ''
    ) {
      if (err.response.data.code === 'ben:5') {
        const { error } = err.response.data
        if (error)
          error[0].forEach((err: any) =>
            dispatch(
              notify(
                `error:${err.error} FIELD:${err.field_name} validformat:${err.valid_format}`,
                'error'
              )
            )
          )
      } else if (err.response.data.error)
        dispatch(notify(err.response.data.error, 'error'))
    } else if (
      err.response.data.error &&
      status === 400 &&
      err.response.data.error !== ''
    ) {
      if (err.response.data.code === 'sec:5') {
        const { error } = err.response.data
        if (error) dispatch(notify(`${error} `, 'error'))
      } else if (err.response.data.error)
        dispatch(notify(err.response.data.error, 'error'))
    } else if (status > 500) {
      dispatch(notify(`Server Error. Status code ${status}`, 'error'))
    }
  }
}
