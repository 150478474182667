import {
  SET_LOCKRATE_FILTER,
  CLEAR_LOCKRATE_FILTER,
  SET_LOCKRATE_ROWS_FILTER,
  SET_LOCKRATE_PAGES_FILTER,
  CLEAR_STORE,
} from '../actions/types'

const initialState = {
  filter: {
    sell_currency: '',
    buy_currency: '',
    min_amount: '',
    max_amount: '',
    start_date: '',
    end_date: '',
    sort_order: 'descending',
    state: [],
    take: 10,
    page: 1,
    sort_by: 'scheduledAt',
  },
}

const lockratesFilter = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_LOCKRATE_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...payload },
      }
    case SET_LOCKRATE_ROWS_FILTER:
      return {
        ...state,
        filter: { ...state.filter, take: payload },
      }
    case SET_LOCKRATE_PAGES_FILTER:
      return {
        ...state,
        filter: { ...state.filter, page: payload },
      }
    case CLEAR_LOCKRATE_FILTER:
      return {
        ...state,
        filter: payload,
      }
    case CLEAR_STORE:
      return initialState

    default:
      return state
  }
}

export default lockratesFilter
