import {
  SET_PAYMENT_FILTER,
  SET_PAYMENTROWS_FILTER,
  SET_PAYMENTPAGES_FILTER,
  CLEAR_STORE,
} from '../actions/types'

const initialState = {
  filter: {
    currency: '',
    min_amount: '',
    max_amount: '',
    start_date: '',
    end_date: '',
    scheduled_start_date: '',
    scheduled_end_date: '',
    sort_order: 'descending',
    state: [],
    take: 10,
    page: 1,
    sort_by: 'createdAt',
  },
}

const paymentFilter = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case SET_PAYMENT_FILTER:
      return {
        ...state,
        filter: { ...state.filter, ...payload },
      }
    case SET_PAYMENTROWS_FILTER:
      return {
        ...state,
        filter: { ...state.filter, take: payload },
      }
    case SET_PAYMENTPAGES_FILTER:
      return {
        ...state,
        filter: { ...state.filter, page: payload },
      }
    case CLEAR_STORE:
      return initialState
    default:
      return state
  }
}

export default paymentFilter
