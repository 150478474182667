import {
  SAVE_SIGNALR_MESSAGE,
  CLEAR_MESSAGES,
  DELETE_SIGNALR_MESSAGE,
  DELETE_SIGNALR_MESSAGES,
  CURRENT_SIGNALR_MESSAGES,
  DELETE_CURRENT_SIGNALR_MESSAGE,
} from 'actions/types'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'

const initialState = {
  messages: [],
  currentSignalRMessages: [],
}

const signalR = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case DELETE_CURRENT_SIGNALR_MESSAGE:
      return {
        ...state,

        currentSignalRMessages: state.currentSignalRMessages.filter(
          (item: any) => item.uid !== payload
        ),
      }
    case CURRENT_SIGNALR_MESSAGES:
      return {
        ...state,

        currentSignalRMessages: [
          ...state.currentSignalRMessages,
          {
            ...payload,

            uid: uuidv4(),
            time: moment().format('DD-MM-YYYY hh:mm:ss'),
          },
        ],
      }
    case SAVE_SIGNALR_MESSAGE:
      return {
        ...state,

        messages: [
          ...state.messages,
          {
            ...payload,

            uid: payload.uid || uuidv4(),
            time: moment().format('DD-MM-YYYY hh:mm:ss'),
          },
        ],
        currentSignalRMessages: state.currentSignalRMessages.filter(
          (item: any) => item.uid !== payload.uid
        ),
      }
    case DELETE_SIGNALR_MESSAGE:
      return {
        ...state,

        messages: state.messages.filter((item: any) => item.uid !== payload),
      }
    case DELETE_SIGNALR_MESSAGES:
      return {
        ...state,

        messages: state.messages.filter(
          (message: any) => !payload.includes(message.type)
        ),
        currentSignalRMessages: state.currentSignalRMessages.filter(
          (message: any) => !payload.includes(message.type)
        ),
      }
    case CLEAR_MESSAGES:
      return initialState
    default:
      return state
  }
}

export default signalR
