import { MembersStateI } from 'types/members'
import {
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  DELETE_MEMBER_REQUEST,
  DELETE_MEMBER_SUCCESS,
  DELETE_MEMBER_FAILURE,
  SEND_INVITATION_REQUEST,
  SEND_INVITATION_SUCCESS,
  SEND_INVITATION_FAILURE,
  CANCEL_INVITATION_REQUEST,
  CANCEL_INVITATION_SUCCESS,
  CANCEL_INVITATION_FAILURE,
  RESEND_INVITATION_REQUEST,
  RESEND_INVITATION_SUCCESS,
  RESEND_INVITATION_FAILURE,
  CLEAR_INVITATION_STATE,
} from '../actions/types'

import _ from 'lodash'

const initialState: MembersStateI = {
  members: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
    items: {
      invitations: [],
      members: [],
    },
  },
  sendInviteState: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
  },
  cancelInvitationState: {
    isFetching: false,
    isFetched: false,
    isFailed: false,
  },
}

const members = (state = initialState, action: any) => {
  const { type, payload } = action

  switch (type) {
    case FETCH_MEMBERS_REQUEST:
      return {
        ...state,

        members: {
          ...state.members,

          isFetching: true,
          isFetched: false,
        },
      }
    case FETCH_MEMBERS_SUCCESS:
      return {
        ...state,
        members: {
          ...state.members,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          items: {
            ...state.members.items,

            members: _.orderBy(payload.members, ['first_name'], ['asc']),
            invitations: _.orderBy(payload.invitations, ['email'], ['asc']),
          },
        },
      }
    case FETCH_MEMBERS_FAILURE:
      return {
        ...state,
        members: {
          ...state.members,

          isFetching: false,
          isFetched: true,
          isFailed: true,
        },
      }
    case UPDATE_MEMBER_REQUEST: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            members: state.members.items.members.map(item => {
              if (item.user_id === payload.id) {
                return {
                  ...item,

                  changingRole: true,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case UPDATE_MEMBER_SUCCESS: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            members: state.members.items.members.map(item => {
              if (item.user_id === payload.id) {
                return {
                  ...item,

                  changingRole: false,
                  role: payload.value,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case UPDATE_MEMBER_FAILURE: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            members: state.members.items.members.map(item => {
              if (item.user_id === payload.id) {
                return {
                  ...item,

                  changingRole: false,
                  changeRoleError: 'Error',
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case RESEND_INVITATION_REQUEST: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            invitations: state.members.items.invitations.map(item => {
              if (item.id === payload.id) {
                return {
                  ...item,

                  isResending: true,
                  isResent: false,
                  isResendFailed: false,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case RESEND_INVITATION_SUCCESS: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            invitations: state.members.items.invitations.map(item => {
              if (item.id === payload.id) {
                return {
                  ...item,

                  isResending: false,
                  isResent: true,
                  isResendFailed: false,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case RESEND_INVITATION_FAILURE: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            invitations: state.members.items.invitations.map(item => {
              if (item.id === payload.id) {
                return {
                  ...item,

                  isResending: false,
                  isResent: false,
                  isResendFailed: true,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case DELETE_MEMBER_REQUEST: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            members: state.members.items.members.map(item => {
              if (item.user_id === payload.id) {
                return {
                  ...item,

                  isDeleting: true,
                  isDeleted: false,
                  isDeleteFailed: false,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case DELETE_MEMBER_SUCCESS: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            members: state.members.items.members.filter(
              item => item.user_id !== payload.id
            ),
          },
        },
      }
    }
    case DELETE_MEMBER_FAILURE: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            members: state.members.items.members.map(item => {
              if (item.user_id === payload.id) {
                return {
                  ...item,

                  isDeleting: false,
                  isDeleted: false,
                  isDeleteFailed: true,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case CANCEL_INVITATION_REQUEST: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            invitations: state.members.items.invitations.map(item => {
              if (item.id === payload.id) {
                return {
                  ...item,

                  isCanceling: true,
                  isCanceled: false,
                  isCancelFailed: false,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case CANCEL_INVITATION_SUCCESS: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            invitations: state.members.items.invitations.filter(
              item => item.id !== payload.id
            ),
          },
        },
      }
    }
    case CANCEL_INVITATION_FAILURE: {
      return {
        ...state,

        members: {
          ...state.members,

          items: {
            ...state.members.items,

            invitations: state.members.items.invitations.map(item => {
              if (item.id === payload.id) {
                return {
                  ...item,

                  isDeleting: false,
                  isDeleted: false,
                  isDeleteFailed: true,
                }
              } else {
                return item
              }
            }),
          },
        },
      }
    }
    case CLEAR_INVITATION_STATE: {
      return {
        ...state,

        sendInviteState: initialState.sendInviteState,
      }
    }
    case SEND_INVITATION_REQUEST: {
      return {
        ...state,

        sendInviteState: {
          ...state.sendInviteState,

          isFetching: true,
          isFetched: false,
          isFailed: false,
          reason: '',
        },
      }
    }
    case SEND_INVITATION_SUCCESS: {
      return {
        ...state,

        sendInviteState: {
          ...state.sendInviteState,

          isFetching: false,
          isFetched: true,
          isFailed: false,
          ...payload,
          reason: '',
        },
        members: {
          ...state.members,

          items: {
            ...state.members.items,

            invitations: payload.invited
              ? [...state.members.items.invitations, payload]
              : state.members.items.invitations,
          },
        },
      }
    }
    case SEND_INVITATION_FAILURE: {
      return {
        ...state,

        sendInviteState: {
          ...state.sendInviteState,

          isFetching: false,
          isFetched: true,
          isFailed: true,
          ...payload,
        },
      }
    }
    default:
      return state
  }
}

export default members
