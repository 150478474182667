import { ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR } from './types'

interface NotificationsI {
  key: number
  message: string
  type?: string
  options: {
    key: number
    variant?: string
  }
}

export const enqueueSnackbar =
  (notification: NotificationsI) => async (dispatch: any) => {
    const key = notification.options && notification.options.key

    dispatch({
      type: ENQUEUE_SNACKBAR,
      notification: {
        ...notification,
        key: key || new Date().getTime() + Math.random(),
      },
    })
  }

export const closeSnackbar = (key: number) => async (dispatch: any) => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
})

export const removeSnackbar = (key: number) => ({
  type: REMOVE_SNACKBAR,
  key,
})

export const notify =
  (message: string, type?: string) => async (dispatch: any) => {
    if (typeof message !== 'string') return

    dispatch(
      enqueueSnackbar({
        key: new Date().getTime() + Math.random(),
        message,
        type,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: type,
        },
      })
    )
  }
